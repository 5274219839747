<template>
	<div class="wrap">
		<div style="width: 600px;margin: auto;">
			<div class="wrap-title">{{ $t('login.title') }}</div>
			<el-form :model="dynamicValidateForm" ref="dynamicValidateForm" label-width="200px" class="demo-dynamic"
				hide-required-asterisk="">
				<el-form-item prop="email" :label="$t('login.emailLabel')" :rules="[
				  { required: true, message: $t('login.pleaseInputEmail'), trigger: 'blur' },
				  { type: 'email', message: $t('login.pleaseInputCorrectEmail'), trigger: 'blur' }
				]">
					<el-input v-model="dynamicValidateForm.email">
						<el-button @click="getVerifycode" slot="append">
							<span v-if="verifying">{{ verifyCodeTime }}s</span>
							<span v-else>{{ $t("login.getVerifyCode") }}</span>
						</el-button>
					</el-input>
				</el-form-item>
				<el-form-item prop="code" :label="$t('login.verifyCodeLabel')" :rules="[
				  { required: true, message: $t('login.pleaseInputVerifyCode'), trigger: 'blur' }
				]">
					<el-input v-model="dynamicValidateForm.code"></el-input>
				</el-form-item>
				
				<el-form-item>
					<div class="el-row">
						 <el-col :span="6">
							 <el-checkbox v-model="checked">{{ $t('login.disclaimer') }}
							 </el-checkbox>
						 </el-col>
						  <el-col :span="12">
							  <div style="color: #FBB450;" @click="outerVisible=true" >{{ $t('login.coollingAgreement') }}</div>
						  </el-col>
						
						
					</div>
					
				</el-form-item>
				
				<el-form-item>
					<el-button type="primary" @click="submitForm('dynamicValidateForm')" style="width:400px;">
						{{ $t('login.submit') }}
					</el-button>
				</el-form-item>
				
				
				<el-dialog :visible.sync="outerVisible">
				   <p v-html='article' v-if="article"></p>
				</el-dialog>
				
			</el-form>
		</div>
	</div>
</template>
<script>
	import {
		getEmail,
		getUserInfo,
		login
	} from "@/api/User";
	import {
		mapMutations
	} from "vuex";
	import {
		setToken
	} from "@/utils/auth";
	
	import Plugin from '@/plugins/i18n.js';

	export default {

		data() {
			return {
				checked: true,
				dynamicValidateForm: {
					// email: '728643788@qq.com',
					// code: '123123',
					email: '',
					code: ''
					
				},
				// 验证码倒计时
				verifyCodeTimeConfig: 60,
				verifyCodeTime: 0,
				verifying: false,
				verifyingInterval: null,
				article:'',
				outerVisible:false,
				locale:''
			}
		},
		
	
		computed:{
			watchTxt(){
				var txt=this.$t('login.submit');
				return txt;
			}
		},
		watch:{
			watchTxt:{
				immediate:true,
				handler(v){
					if(v=='提交'){
						this.getArticle(8)
					}else{
						this.getArticle(13)
					}
				}
			}
		},
		methods: {
			
             getArticle(e){
				 this.$req.get("/client/cms/article/get", {
				 	id:e
				 }).then( res => {
	                  this.article=res.content;
				 })
			 },
			// 表单提交
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.handleLogin()
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			// 登录
			handleLogin() {
				login(this.dynamicValidateForm).then(res => {
					if (res && res.code == 10001) {
						//需要注册
						this.$message.warning("需要注册")
						this.$router.push({
							path: "/accout",
							query: {
								email: this.dynamicValidateForm.email
							},
						})
					} else {
						setToken(res)
						this.$message(this.$t("newadd.t.登陆成功"));
						this.$router.push({
							path: "/"
						}, () => {
							location.reload()
						});
					}
				})
			},
			// 开启倒计时
			startVerifyInterval() {
				this.verifyCodeTime = this.verifyCodeTimeConfig;
				this.verifying = true;

				this.verifyingInterval = setInterval(() => {
					if (this.verifyCodeTime == 0) {
						clearInterval(this.verifyingInterval);
						this.verifying = false;
					} else {
						this.verifyCodeTime--;
					}
				}, 1000);
			},
			// 获取验证码
			getVerifycode() {
				let email = this.dynamicValidateForm.email;
				if (!/^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/.test(email)) return;
				if (this.verifying) return;
				this.startVerifyInterval();
				getEmail(email, true)
			}
		},
		beforeDestroy() {
			if (this.verifyingInterval)
				clearInterval(this.verifyingInterval);
		}
	}
</script>
<style scoped>
	.wrap {
		background: #fff;
		padding: 40px 0;
		height: 100vh;
	}


	.wrap-title {
		text-align: center;
		font-size: 24px;
		margin: 30px auto;
		width: 100%;
	}

	.el-input {
		width: 400px;
	}

	.el-checkbox {
		text-align: center;
		color: 000;
	}

	.register-get-verifycode {
		position: absolute;
		right: 80px;
		top: 0;
		width: 120px;
		border: 1px solie red;
		color: #888;
		cursor: pointer;
	}
</style>
